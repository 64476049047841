import {ReactComponent as GITHUB} from '../icons/github.svg'
import {ReactComponent as INSTAGRAM} from '../icons/instagram.svg'
import {ReactComponent as LINKEDIN} from '../icons/linkedin.svg'
import {ReactComponent as RESUME} from '../icons/resume.svg'

const links_icons =
[
    {
        link : 'https://github.com/krishnajalan/',
        icon : GITHUB
    },
    {
        link : 'https://www.linkedin.com/in/krishnajalan/',
        icon : LINKEDIN
    },
    {
        link : 'https://www.instagram.com/krishna_jalan_/',
        icon : INSTAGRAM
    },
    {
        link : 'https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bm7yfrw0djw1/b/bucket-20220628-0830/o/Krishnajalan_SDE_resume.pdf',
        icon : RESUME
    },
]
export default links_icons
